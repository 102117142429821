import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from '../../../core/components/menu/Menu';
import { MenuEntity } from '../../../core/components/menu/entities/MenuEntity';
import { defaultHeaderText, IHeaderText } from '../../entities/HeaderText';
import { IRoute } from '../../entities/Route';
import Body from '../components/Body';
import Header from '../components/Header';

interface IComponentInfo {
    children?: any;
    toggleCollapse: () => void;
    menuCollapsed: boolean;
    siteMenu: MenuEntity[];
    isLoadingMenu: boolean;
    routes: IRoute[];
}

const DefaultLayout: React.FunctionComponent<IComponentInfo> = ({
    children, isLoadingMenu, siteMenu, toggleCollapse, menuCollapsed, routes
}) => {

    const { pathname } = useLocation();
    const [pageName, setPageName] = useState('')
    const [headerText, setHeaderText] = useState<IHeaderText>(defaultHeaderText)

    useEffect(() => {
        const page = routes.filter(rt => rt.path === pathname);
        setPageName(page[0]?.name ?? "")
        if (pathname === '/home' || pathname === '/') {
            setHeaderText({
                ...defaultHeaderText,
                middle: 'Welcome to the Kentucky School Report Card',
                left: 'Report Card'
            });
        } else {
            setHeaderText({
                ...defaultHeaderText,
                middle: 'Kentucky',
                left: 'Report Card'
            });
        }
    }, [pathname, routes])

    return (
        <>
            <Header
                collapsed={menuCollapsed}
                toggleCollapse={toggleCollapse}
                headerText={headerText}
                isCenter={false}
                pathname={pathname}
                layout={2}
            />
            <div className='layout-definition report-embedded m-b-0' style={{ height: 'calc(100% - 80px)' }} >
                {pathname !== '/' && pathname !== '/home' && (
                    <div className='three-tier'>
                        <div className="left-nav">
                            <Menu items={siteMenu} collapsed={menuCollapsed} isLoading={isLoadingMenu} />
                        </div>
                    </div>
                )}
                <Body
                    children={children}
                    collapsed={menuCollapsed}
                    siteMenu={siteMenu}
                    pathname={pathname}
                    pageName={pageName}
                    headerText={headerText}
                />
            </div >

        </>
    );
};

export default DefaultLayout;