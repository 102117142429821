import React, { Children, cloneElement, useEffect, useState } from 'react';
import { getSiteSetting, SiteSetting } from '../../entities/SiteSettings';

import { MenuEntity } from '../../../core/components/menu/entities/MenuEntity';
import { IHeaderText } from '../../entities/HeaderText';

import { IBreadcrumb } from '../../../core/components/breadcrumbs/entities/Breadcrumb';
import OEBreadcrumb from '../../../core/components/breadcrumbs/OEBreadcrumb';
import { navigateToPageReactRouter } from '../../../core/utilities/URL';
import { useNavigate } from 'react-router';

interface IComponentInfo {
    children?: any;
    collapsed: boolean;
    siteMenu: MenuEntity[];
    pathname: string;
    pageName: string;
    headerText: IHeaderText;
}

const pagesWithoutScroll = ['/glossary'];

const Body: React.FunctionComponent<IComponentInfo> = ({ children, collapsed, siteMenu, pathname = '', pageName, headerText }) => {

    const rootBreadcrumbs: IBreadcrumb[] = [{ id: 0, name: 'Home' }];
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(rootBreadcrumbs);
    const navigate = useNavigate()

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        navigateToPageReactRouter(navigate, '/home')
    };

    useEffect(() => {
        setBreadcrumbs([...rootBreadcrumbs, { id: 1, name: pageName }]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageName]);

    return (
        <div className='body-container'
            style={{
                width: pathname === "/home" || pathname === '/' ? '100%' : 'calc(100% - 290px)',
                overflow: pagesWithoutScroll.includes(pathname) ? 'unset' : 'overlay',
            }}>
            {pathname !== "/" && pathname !== "/home" && (
                <>
                    <div className="subheader" style={{ display: 'flex', alignItems: 'center' }}>
                        <p className='top-left-text'>{pageName}</p>
                    </div>
                    <div className="breadcumb-container">
                        <OEBreadcrumb
                            breadcrumbs={breadcrumbs}
                            setBreadcrumbs={setBreadcrumbs}
                            navigateBreadcrumb={onNavigateBreadcrumb}
                        />
                    </div>
                </>
            )}

            {Children.map(children, (child) => {
                return cloneElement(child, {
                    children: Children.map(child.props.children, (child_2) => {
                        return cloneElement(child_2, {
                            element: React.isValidElement(child_2.props.element) ? cloneElement(child_2.props.element, { siteMenu, selectedYear: getSiteSetting(SiteSetting.AcademicYear) }) : child_2.props.element
                        })
                    })
                })
            })}
        </div >
    );
};


export default Body;
