import React from 'react';

interface HomeCardProps {
    description: string;
    urlImage: string;
    isFirst: boolean;
    onClick: () => void;
    isBiggerVersion?: boolean;
}

const HomeCard: React.FunctionComponent<HomeCardProps> = ({ description, urlImage, isFirst, onClick, isBiggerVersion }) => {

    if (isBiggerVersion) {
        return (
            <div tabIndex={0} onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') onClick()
            }} className={`widget-container ${isFirst === false ? ' ml-20' : ''}`} onClick={onClick}>
                <div className={`widget-title-container`}>
                    <p className='widget-title'>{description}</p>
                </div>
                <div className='widget-image'>
                    <img src={urlImage} alt="widget_image" style={{ height: 80, marginTop: 10, marginBottom: 10 }} />
                </div>
            </div>
        )
    }

    return (
        <div className='widget-container-smaller' tabIndex={0} onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') onClick()
        }} onClick={onClick}>
            <div className='icon-space'>
                <i className={`m-r-5 ${urlImage} settings`} />
            </div>
            <div className='name-space'>
                <h5>{description}</h5>
            </div>
        </div>
    )
}

export default HomeCard;