import React, { useEffect, useState } from 'react';
import OELink from '../../../../core/components/general/OELink';
import { getMenuList, IMenu, MenuTarget } from '../../../entities/Menu';
import { useGetMenuByParentIdActive } from '../../../services/MenuService';

interface IComponentInfo {
    menu: IMenu;
}

const FooterMenuLinks: React.FunctionComponent<IComponentInfo> = ({ menu }) => {
    const { service: menuService, setParentId } = useGetMenuByParentIdActive(); // Step 2 - load menus

    const [menus, setMenus] = useState<IMenu[]>([]);

    useEffect(() => {
        setParentId(menu.id);
        // eslint-disable-next-line
    }, [menu]);

    useEffect(() => {
        if (menuService.result !== undefined) {
            setMenus(getMenuList(menuService.result));
        }
        // eslint-disable-next-line
    }, [menuService]);

    return (
        <>
            {menus.map((item) =>
                <p className="link" key={item.id} >
                    <OELink
                        href={item.path}
                        className={`link`}
                        aria-current="page"
                        target={item.target || MenuTarget.Self}
                    >
                        {item.name}
                    </OELink>
                </p>
            )}
        </>
    );
};


export default FooterMenuLinks;
