import React, { useEffect, useState } from 'react';
import { ICarousalItem } from '../../../../core/components/carousal/entities/Carousal';
import { navigateToPage } from '../../../../core/utilities/URL';
import { useGetSchoolDistricts } from '../../../../reporting/services/ReportFilterService';
import { getSiteSetting, SiteSetting } from '../../../entities/SiteSettings';
import OrganizationItem from './OrganizationItem';

interface IComponentInfo {
    typeId: string;
}

const OrganizationList: React.FunctionComponent<IComponentInfo> = ({ typeId }) => {

    // const [selectedItem, setSelectedItem] = useState(0); // Carousal Changes
    const [selectedItem, setSelectedItem] = useState('A');

    const { service: orgService, setSearch } = useGetSchoolDistricts();
    const [items, setItems] = useState<ICarousalItem[]>([])

    const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

    useEffect(() => {
        setSearch({ keyword: '%', academicYear: getSiteSetting(SiteSetting.AcademicYear), isSchool: true });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (orgService.result) {
            const d: ICarousalItem[] = [];
            for (const v of orgService.result.filter(q => q.typeId === typeId)) {
                d.push({ value: v.id, title: v.name });
            }

            setItems(d);
        }
        // eslint-disable-next-line
    }, [orgService]);

    const navigateToSchoolReports = (item: ICarousalItem) => {
        navigateToPage(`/embedded-public?pid=c340f7d5-efbd-5fb8-cab8-3a128835f84c&organization=${item.value}`)
    }

    const handleLetterClick = (letter: string) => {
        setSelectedItem(letter)
    };

    const getName = () => {
        switch (typeId) {
            case "002":
                return "Districts"
            case "003":
                return "Schools"
            default:
                return ""
        }
    }

    return (
        // Carousal Changes
        // <div className="p-3 flex-grow-1 bg-white">
        //     <Carousal onClickItem={(i) => { console.log("Schools click", i) }} selectedItem={selectedItem} items={SCHOOL_TITLE} setSelectedItem={setSelectedItem} />
        //     <OESpinner className="d-flex justify-content-center" hidden={!orgService.isInProgress} />

        //     {!orgService.isInProgress && (
        //         <>
        //             <div className="schoolContainer">
        //                 <OrganizationItem titleIndex={0} items={items} selectedItem={selectedItem} navigateToSchoolReports={navigateToSchoolReports} />
        //                 <OrganizationItem titleIndex={1} items={items} selectedItem={selectedItem} navigateToSchoolReports={navigateToSchoolReports} />
        //                 <OrganizationItem titleIndex={2} items={items} selectedItem={selectedItem} navigateToSchoolReports={navigateToSchoolReports} />
        //             </div>
        //         </>
        //     )}
        // </div>
        <div className="p-3 flex-grow-1 bg-white" style={styles.container}>
            <p className='default-title-body'>{getName()}</p>
            <div className="container-options">
                {alphabet.map((letter) => (
                    <div className='glossary-link-container' key={letter}>
                        <button className={`glossary-link ${selectedItem === letter ? 'glossary-link-active' : ''}`} onClick={() => handleLetterClick(letter)} >
                            {letter}
                        </button>
                    </div>
                ))}
            </div>
            <div className="schoolContainer">
                <OrganizationItem titleIndex={0} items={items} selectedItem={selectedItem} navigateToSchoolReports={navigateToSchoolReports} />
            </div>
        </div>
    );
};

export default OrganizationList;

const styles = {
    container: {
        height: 'calc(100 % - 300px)'
    } as React.CSSProperties,
};
