import React, { useState } from 'react';
import { IHeaderText } from '../../entities/HeaderText';
import { displayHTMLContent } from '../../../core/utilities/Miscellaneous';
import { getCurrentLanguage } from '../../entities/SiteSettings';

interface IComponentInfo {
    toggleCollapse?: () => void;
    collapsed: boolean;
    headerText: IHeaderText;
    isCenter?: boolean;
    pathname?: string;
    headerWidth?: number;
    layout: number;
}

const Header: React.FunctionComponent<IComponentInfo> = ({ collapsed, headerText, isCenter = false, pathname, headerWidth, layout }) => {
    const [language, setLanguage] = useState<string>(getCurrentLanguage());

    const saveTranslateStorage = (i: string) => {
        localStorage.setItem('translateLang', i);
    }

    const handleTranslateClick = (lang: string) => {
        if (lang !== language) {
            translateLanguage(lang);
            saveTranslateStorage(lang);
            setLanguage(lang);
        }
    };

    const translateLanguage = (lang: string) => {
        const googleTranslateDropdown: any = document.querySelector(".goog-te-combo");
        if (googleTranslateDropdown && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveTranslateStorage(lang);
        }
        if (googleTranslateDropdown && !googleTranslateDropdown.value && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveTranslateStorage(lang);
        }
    }

    const backgroundImageContainer = pathname === "/" || pathname === '/home' ? '#ffffff' : '#f7f7f7';
    const backgroundColorHeader = pathname === "/" || pathname === '/home' ? '#ffffff' : '#007780';


    const renderDefaultHomePageHeader = () => {
        return (
            <div>
                <div className="header-layout" style={{ backgroundColor: backgroundColorHeader }}>
                    <div style={{
                        width: collapsed ? 80 : 290,
                        backgroundColor: backgroundImageContainer,
                        height: 80
                    }}>
                        <img
                            height={80}
                            className="header-logo"
                            src={`/images/logo/${collapsed ? 'header-logo-collapsed.png' : 'header-logo-background.png'}?v=${Math.random() * 1000}`}
                            alt=""
                        />
                    </div>
                    <div className="dropdown text-end profile-container"
                        style={{
                            justifyContent: 'right',
                            backgroundColor: '#fff'
                        }}>
                        <span
                            className='translate-container header-home-container'
                            onClick={() => handleTranslateClick(language === "en" ? 'es' : 'en')}
                            dangerouslySetInnerHTML={displayHTMLContent(language === "en" ? 'Español' : 'Inglés')} />
                    </div>
                </div >
            </div >
        )
    }

    const renderHeaderLayouts = () => {
        return (
            <div>
                <div className="header-layout" style={{ backgroundColor: '#ffffff' }}>
                    <div style={{
                        width: collapsed ? 80 : 290,
                        backgroundColor: backgroundImageContainer,
                        height: 80
                    }}>
                        <img
                            height={80}
                            className="header-logo"
                            src={`/images/logo/${collapsed ? 'header-logo-collapsed.png' : 'header-logo-background.png'}?v=${Math.random() * 1000}`}
                            alt=""
                        />
                    </div>
                    <div className="dropdown text-end profile-container"
                        style={{
                            justifyContent: isCenter ? 'center' : 'space-between',
                            alignItems: 'center'
                        }}>
                        <div className="m-l-10 text-header" style={{ color: 'black' }}>
                            <h3>Kentucky School Report Card</h3>
                        </div>
                        <div className="m-l-10 text-header">
                            <div className='icon-container-header'>
                                <i className="fa fa-search" style={{ fontSize: 24 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    return (
        <>
            {layout === 2 && (pathname === "/" || pathname === "/home")
                ? renderDefaultHomePageHeader()
                : renderHeaderLayouts()}
        </>
    );
}

export default Header;