export interface IHeaderText {
    left: string;
    right: string;
    middle: string;
    language: string;
    year: string;
    school: string;
    district: string;
}

export const defaultHeaderText: IHeaderText = {
    left: '', right: '2023-2024', middle: 'School Report Card', language: `Espa&#241;ol`,
    year: '', school: '', district: ''
};

export const defaultHeaderTextEmtpy: IHeaderText = {
    left: '', right: '', middle: '', language: `Espa&#241;ol`,
    year: '', school: '', district: ''
};
