
export interface IReportPagePublicFilter {
    id: string;
    organization: string;
}

export const defaultReportPagePublicFilter = {
    id: '', organization: ''
};

