import { MenuProps } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { Icon } from '../../../core/entities/Icon';
import { IMenu } from '../../../site/entities/Menu';
import { navigateToPage, navigateToPageReactRouter } from '../../utilities/URL';
import OEIcon from '../general/OEIcon';
import { getUrlParameters } from '../parameters/entities/OEParameter';
import { MenuEntity } from './entities/MenuEntity';

export type MenuItem = Required<MenuProps>['items'][number];

export const getMenuChildren = (p: IMenu[], pid: number, rid: number, navigate: Function): MenuEntity[] => {
    const l: MenuEntity[] = [];
    for (const i of p.filter(q => q.parentId === pid)) {
        i.rootId = pid > 0 ? rid : i.id;
        const children = getMenuChildren(p, i.id, i.rootId, navigate);
        const f: MenuEntity =
        {
            icon: i.icon,
            key: i.id,
            label: i.name,
            children: children,
            onClick: () => children?.length > 0
                ? () => { }
                : i?.externalUrl !== ""
                    ? navigateToPage(i.externalUrl)
                    : navigateToPageReactRouter(navigate, `${i?.path}` + getUrlParameters(i.parameters))
        }

        l.push(f);
    }
    return l;
}

interface IComponentInfo {
    collapsed: boolean;
    items: MenuEntity[];
    isLoading: boolean;
    onMouseOut?: Function;

}

const Menu: React.FunctionComponent<IComponentInfo> = ({ collapsed, items, isLoading, onMouseOut }) => {

    const [activeLabels, setActiveLabels] = useState<string[]>([]);

    useEffect(() => {
        const active = localStorage.getItem('activeLabels');
        if (active) {
            setActiveLabels(JSON.parse(active));
        }
    }, [])

    useEffect(() => {
        if (activeLabels) {
            localStorage.setItem('activeLabels', JSON.stringify(activeLabels));
        }
    }, [activeLabels])

    const renderChild = (children: MenuEntity[], level: number, labels: string[]): any => {
        if (children?.length > 0) {
            return children.map((item) => {
                return (
                    <Fragment key={item.key} >
                        <div
                            tabIndex={0}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' || event.key === ' ') {
                                    setActiveLabels([...labels, item.label]);
                                    item.onClick(event as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>);
                                }
                            }}
                            role="button"
                            className={`section-item ${activeLabels.includes(item.label) ? 'active' : ''}`}
                            style={{ padding: `8px 8px 8px ${level * 50}px` }}
                            onClick={(e) => {
                                item.onClick(e);
                                setActiveLabels([...labels, item.label]);
                            }}
                        >
                            <div onClick={() => { }} >
                                {item.icon && (
                                    <i className={`m-r-5 ${item.icon}`} style={{ width: 20 }} />
                                )}
                                <span>{item.label}</span>
                                {item.children.length > 0 && activeLabels.includes(item.label) && (<OEIcon icon={Icon.CaretUp} className="pull-right" />)}
                                {item.children.length > 0 && !activeLabels.includes(item.label) && (<OEIcon icon={Icon.CaretDown} className="pull-right" />)}
                            </div>
                        </div>
                        {activeLabels.includes(item.label) && renderChild(item.children, level + 1, [...labels, item.label])}
                    </Fragment>
                )
            })
        }

        return null
    }

    return (
        <>
            {items.map((item, idx) => {
                return (
                    <Fragment key={item.key} >
                        <div
                            key={item.key}
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    setActiveLabels([item.label]);
                                    item.onClick(e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>);
                                }
                            }}
                            role="button"
                            className={`section-header ${activeLabels.includes(item.label) ? 'active' : ''}`}
                            onClick={(e) => {
                                item.onClick(e);
                                setActiveLabels([item.label])
                            }}
                        >
                            {item.icon && (
                                <i className={`m-r-5 ${item.icon}`} style={{ width: 20 }} />
                            )}
                            <span>
                                {item.label+""}
                            </span>
                            {item.children.length > 0 && activeLabels.includes(item.label) && (<OEIcon icon={Icon.CaretUp} className="pull-right" />)}
                            {item.children.length > 0 && !activeLabels.includes(item.label) && (<OEIcon icon={Icon.CaretDown} className="pull-right" />)}
                        </div>
                        {activeLabels.includes(item.label) && renderChild(item.children, 1, [item.label])}
                    </Fragment>
                )
            })}

        </>

    );
};

export default Menu;