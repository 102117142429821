
export interface ISchoolDistrictSearch {
    keyword: string;
    academicYear: string;
    isSchool: boolean;
}

export const defaultSchoolDistrictSearch: ISchoolDistrictSearch = {
    keyword: '', academicYear: '', isSchool: false
};

