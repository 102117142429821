import React from 'react';

import { AutoComplete } from 'antd';
import { CSSProperties } from 'styled-components';


export interface IAutoCompleteOptionSelections {
    value: string;
    label: string;
}

interface IComponentInfo {
    options: IAutoCompleteOptionSelections[];
    title: string;
    onNavigate: (i: string) => void;
    onSearch: (i: string) => void;
    style?: CSSProperties;
    value: string;
    placeholder: string;
    titleStyle?: string;
}

const OEAutoComplete: React.FunctionComponent<IComponentInfo> = ({ options, style, title, onNavigate, onSearch, value, placeholder, titleStyle }) => {
    return (
        <>
            {title && (
                <p className={titleStyle ?? ""}>{title}</p>
            )}
            <div style={{ position: 'relative' }}>
                <AutoComplete
                    options={options}
                    onSelect={onNavigate}
                    onSearch={(text) => onSearch(text)}
                    value={value}
                    style={style}
                    variant='filled'
                >
                    <input className='search-input'></input>
                </AutoComplete>
                <div className='search-icon-container'>
                    <i className="fa fa-search" style={{ width: 50 }} />
                </div>
            </div>
        </>

    );
};

export default OEAutoComplete;