import React from 'react';
import { IMenu } from '../../../entities/Menu';
import FooterMenuLinks from './FooterMenuLinks';

interface IComponentInfo {
    menus: IMenu[];
}

const FooterMenus: React.FunctionComponent<IComponentInfo> = ({ menus }) => {
    return (
        <div className='info-links'>
            {menus.map((menu) =>
                <div key={menu.id} className='col-links'>
                    <p className='title'>{menu.name}</p>
                    <FooterMenuLinks menu={menu} />
                </div>
            )}
        </div>
    );
};


export default FooterMenus;
